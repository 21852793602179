import Button from "@mui/material/Button";
import "styles/button.css";
import "styles/card.css";
import { useNavigate } from "react-router-dom";
import React from "react";
import WineLogo from "./WineLogo";
export default function Card({
  image,
  title,
  titleColor,
  descColor,
  description,
  textButton,
  classButton,
  urlButton,
  borderColor,
}) {
  const navigate = useNavigate();

  function switchToWinePal(urlButton) {
    navigate(urlButton);
  }

  return (
    <div className={`main-card ${borderColor}`}>
      <h2 className={titleColor}>{title}</h2>
      <img src={image} alt={title} />
      <p className={descColor}>{description}</p>
      <div onClick={() => switchToWinePal(`${urlButton}`)}>
        <Button variant="contained" className={`${classButton} white`}>
          <div style={{marginRight: 20}}>
            <WineLogo fillColor={'#f9e7ef'}/>
          </div>
          {textButton}
        </Button>
      </div>
    </div>
  );
}
