import React from "react";
import Header from "components/Header";
import Card from "components/Card";
import Welcome from "components/Welcome";
import vins from "assets/icons/vins.svg";
import plats from "assets/icons/plats.svg";
import ray from "assets/icons/ray.svg";
import "styles/home.css";
import Footer from "components/Footer";
import Blog from "components/Blog";

export default function Home() {
  return (
    <div className="home-container">
      <Header />
      <div className="main-container">
        <div className="card">
          <Card
            image={vins}
            title={"Vins"}
            titleColor={"title-primary"}
            descColor={"desc-primary"}
            description={"Générez 3 vins différents à accorder avec votre plat"}
            textButton={"GENERER MON VIN"}
            classButton={"primary mid"}
            urlButton={"/wine"}
            borderColor={"border-primary"}
          />
          <Card
            image={ray}
            title={"En rayon ?"}
            titleColor={"title-primary"}
            descColor={"desc-primary"}
            description={"Générez vos accords via un partenaire"}
            textButton={"GENERER UN ACCORD"}
            classButton={"primary mid"}
            urlButton={"/generate"}
            borderColor={"border-primary"}
          />
          <Card
            image={plats}
            title={"Plats"}
            titleColor={"title-primary"}
            descColor={"desc-primary"}
            description={"Générez un plat à accorder avec votre vin"}
            textButton={"GENERER MON PLAT"}
            classButton={"primary plat"}
            urlButton={"/pal"}
            borderColor={"border-primary"}
          />
        </div>
        <Welcome />
        {/* <div className="blog">
            <Blog />
        </div> */}
      </div>
        <Footer />
    </div>
  );
}
