import React from "react";
import "styles/header.css";
import wineGlass from "assets/icons/wine-glass.svg";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();

  function switchToWine() {
    navigate("/wine");
  }

  return (
    <div className="main-header">
      <img src={wineGlass} alt={'wine'} />
      <h1 className="header-title">WinePal</h1>
      <p>L’accord parfait, à portée de main !</p>
      <div className="mouse-container">
        <div className="mouse">
          <div className="wheel"></div>
        </div>
      </div>
      {/* <div className="header-form">
        <input type="text" placeholder="Rechercher votre accord-mets..." />
        <button onClick={switchToWine}>GO</button>
      </div> */}
    </div>
  );
}
