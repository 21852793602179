import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import "styles/forgotPassword.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiURL } from "services/apiUrl";
import { useDispatch } from "react-redux";
import { showNavbar } from "features/snackbar.slice";
import LoadingButton from "@mui/lab/LoadingButton";

export default function PersonalData() {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [lastName, setLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [email, setEmail] = useState();
    const [checkedLabels, setCheckedLabels] = useState([]);

    const handleCheckboxChange = (label) => {
        const isChecked = checkedLabels.includes(label);

        if (isChecked) {
            setCheckedLabels(checkedLabels.filter((item) => item !== label));
        } else {
            setCheckedLabels([...checkedLabels, label]);
        }
    };

    const sendEmail = async () => {
        setLoading(true);
        await axios
            .post(`${apiURL}/personal-data/`, { lastName, firstName, email, checkedLabels })
            .then(() => {
                setLoading(false);
                dispatch(
                    showNavbar({
                        message: 'Le message a été envoyé.',
                        type: "SUCCESS",
                        open: true,
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    showNavbar({
                        message: err.response.data.MESSAGE,
                        type: "FAIL",
                        open: true,
                    })
                );
            });
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className="forgot-password-main">
            <Typography mb={2} variant="h5" fontWeight={"bold"} textAlign={"center"}>
                Demande de données personnelles
            </Typography>
            <TextField
                type="text"
                sx={{ marginBottom: "1rem" }}
                id="outlined-basic"
                label="Nom"
                variant="outlined"
                onChange={handleFirstNameChange}
            />
            <TextField
                type="text"
                sx={{ marginBottom: "1rem" }}
                id="outlined-basic"
                label="Prenom"
                variant="outlined"
                required={false}
                onChange={handleLastNameChange}
            />
            <TextField
                type="email"
                sx={{ marginBottom: "1rem" }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={handleEmailChange}
            />
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() => handleCheckboxChange("Consulter mes données")}
                        />
                    }
                    label="Consulter mes données"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() => handleCheckboxChange("Modifier mes données")}
                        />
                    }
                    label="Modifier mes données"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() => handleCheckboxChange("Supprimer mes données")}
                        />
                    }
                    label="Supprimer mes données"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() =>
                                handleCheckboxChange(
                                    "M'opposer à l'utilisation de mes données"
                                )
                            }
                        />
                    }
                    label="M'opposer à l'utilisation de mes données"
                />
            </FormGroup>
            <LoadingButton
                onClick={sendEmail}
                loading={loading}
                variant="contained"
                className="load-btn"
            >
                <span>Envoyer</span>
            </LoadingButton>
        </div>
    );
}
