import React, { useState, useEffect, useMemo, useCallback } from "react";
import "styles/biblio.css";
import { Button, Typography } from "@mui/material";
import { apiURL } from "../services/apiUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import "styles/button.css";
import "styles/table.css";

export default function History() {
  let [history, setHistory] = useState([]);
  const token = useSelector((state) => state.user.token);

  async function getHistory(pagination = {}) {
    const response = await axios.get(`${apiURL}/history`, {
      headers: {
        Authorization: token,
      },
      params: pagination,
    });

    setHistory(response.data.DATA);
  }

  useEffect(() => {
    getHistory();
  }, []);

  const render = useCallback((value) => {
    if (history && history.data && 0 < history.data.length) {
      getHistory({
        pagination: {
          field: 'createdAt',
          condition: '$gt',
          value: history.data[0].createdAt,
        },
      });
    }
  }, []);

  const handlePrevious = useCallback(() => {
    if (history && history.data && 0 < history.data.length) {
      getHistory({
        pagination: {
          field: 'createdAt',
          condition: '$gt',
          value: history.data[0].createdAt,
        },
      });
    }
  }, [history, history.data]);

  const handleNext = useCallback(() => {
    if (history && history.data && 0 < history.data.length) {
      getHistory({
        pagination: {
          field: 'createdAt',
          condition: '$lt',
          value: history.data[history.data.length - 1].createdAt,
        },
      });
    }
  }, [history, history.data]);

  useMemo(() => {}, []);

  return (
    <div>
      <Typography variant="h5" mt={1}>
        Historique
      </Typography>

      <div>
        <table className="table">
          <thead style={{ backgroundColor: '#5f0731', color: 'white' }}>
            <th>Type de recherche</th>
            <th>Recherche</th>
            <th>Réponse</th>
            <th>Date de création</th>
          </thead>

          <tbody>
            {history && history.data && history.data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{ item.type }</td>

                  <td>
                    { item.prompt.split('\n\n').map(str => <p>{str}</p>) }
                  </td>

                  <td>
                    { item.response.content.split('\n\n').map(str => <p>{str}</p>) }
                  </td>

                  <td>{ (new Date(item.createdAt)).toLocaleString() }</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        {history && history.havePrevious && (
          <Button
            className="detailsBtn"
            onClick={handlePrevious}
            style={{ float: 'left'}}
          >Page précédente</Button>
        )}

        {history && history.haveNext && (
          <Button
            className="detailsBtn"
            onClick={handleNext}
            style={{ float: 'right'}}
          >Page suivante</Button>
        )}

        <div style={{ clear: 'both' }}></div>
      </div>
    </div>
  );
}
