import React, { useCallback, useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { Helmet } from "react-helmet-async";

const cookieName = 'CookieConsent';

export default function CookieConsentBanner() {
  const [cookieConsentValue, setCookieConsentValue] = useState(() => getCookieConsentValue(cookieName));

  const cookieConsentCallback = useCallback(() => {
    setCookieConsentValue(getCookieConsentValue(cookieName));
  }, []);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accepter les cookies"
        declineButtonText="Refuser les cookies"
        cookieName={cookieName}
        onAccept={cookieConsentCallback}
        onDecline={cookieConsentCallback}
        expires={180}
        enableDeclineButton={true}
      >
        Ce site utilise des cookies pour améliorer l'expérience utilisateur.
      </CookieConsent>

      {'true' === cookieConsentValue && (
        <>
          <Helmet
            script={[
              // Google tag (gtag.js)
              {
                async: true,
                src: "https://www.googletagmanager.com/gtag/js?id=G-14P3DF0Y4R",
              },
              {
                innerHTML: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments);
                  }
                  gtag("js", new Date());

                  gtag("config", "G-14P3DF0Y4R");
                `,
              },
            ]}
          >
          </Helmet>
        </>
      )}
    </>
  );
}
