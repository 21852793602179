export default function WineLogo({ fillColor, width, height }) {
    return (
        <svg
            width={width || "30px"}
            height={height || "3Opx"}
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            className="iconify iconify--emojione-monotone"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M15.061 22.125c.937 7.962 8.162 14.941 16.945 14.941c9.42 0 17.055-8.024 17.055-16.683c0-.074-.005-.146-.006-.217c-9.718-1.319-22.099 5.742-33.994 1.959"
                fill={fillColor || "#5f0731"}
            />
            <path
                d="M54 20.445C54 9.042 48.292 2.002 48.291 2H15.708S10 9.052 10 20.445c0 10.828 9.256 20.886 20.895 21.489c-.136 6.288-.671 12.836-2.211 15.104c-2.169 3.196-9.771 1.554-9.771 4.962H45.09c0-3.409-7.605-1.766-9.774-4.962c-1.54-2.268-2.075-8.815-2.211-15.104C44.741 41.332 54 31.273 54 20.445M32 39.254c-9.794 0-17.85-7.781-18.894-16.658a16.54 16.54 0 0 1-.121-1.941c0-9.85 4.933-15.944 4.933-15.944h28.163c.001.003 4.846 5.989 4.927 15.702c.001.08.007.159.007.242c0 9.653-8.513 18.599-19.015 18.599"
                fill={fillColor || "#5f0731"}
            />
        </svg>
    );
}
