import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthentication,
  setUserIdentity,
  setToken,
} from "features/user.slice";

export default function Hamburger() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userAuthenticated = useSelector((state) => state.user.isAuthenticate);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    dispatch(setAuthentication(false));
    dispatch(setUserIdentity(""));
    dispatch(setToken(""));
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <svg
          height="32"
          width="32"
          version="1.1"
          id="_x32_"
          viewBox="0 0 448 512"
        >
          <g>
            <path fill='#5f0731' d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
          </g>
        </svg>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <NavLink to="/" className="nav-link-hamburger">
          <MenuItem onClick={handleClose}>Accueil</MenuItem>
        </NavLink>

        <NavLink to="/wine" className="nav-link-hamburger">
          <MenuItem onClick={handleClose}>Vins</MenuItem>
        </NavLink>
        <NavLink to="/pal" className="nav-link-hamburger">
          <MenuItem onClick={handleClose}>Plats</MenuItem>
        </NavLink>
        {/* <NavLink to="/blogs" className="nav-link-hamburger">
          <MenuItem onClick={handleClose}>Blog</MenuItem>
        </NavLink> */}
        {userAuthenticated && (
          <NavLink to="/compte/user/info" className="nav-link-hamburger">
            <MenuItem onClick={handleClose}>Compte</MenuItem>
          </NavLink>
        )}
        {!userAuthenticated ? (
          <NavLink to="/login" className="nav-link-hamburger">
            <MenuItem onClick={handleClose}>Connexion</MenuItem>
          </NavLink>
        ) : (
          <NavLink to="/" className="nav-link-hamburger" onClick={logout}>
            <MenuItem onClick={handleClose}>Deconnexion</MenuItem>
          </NavLink>
        )}

        <Outlet />
      </Menu>
    </div>
  );
}
