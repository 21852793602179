import React from "react";
import MarkdowmEditor from "../components/MarkdownEditor";

export default function AddBlog() {
  return (
    <div>
      <MarkdowmEditor />
    </div>
  );
}
